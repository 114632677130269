"use client";

import { colors, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import FooterLogo from "@v2/assets/images/slogan.svg";
import Image from "next/image";
import Link from "next/link";
import FontAwesomeIcon from "../FontAwesomeIcon";
import Enemad from "./enemad";
export default function Footer() {
    const theme = useTheme()

    return (
        <Box component={"footer"} sx={{ borderTop: "1px solid #E4E4E4", pt: 2, bgcolor: '#fff' }}>
            <Box sx={{
                maxWidth: 1400, margin: 'auto', '& a:hover': {
                    color: '#CC2941',
                    '& *': {
                        color: '#CC2941',

                    }
                }
            }}>
                <Grid container sx={{ px: 2 }} >
                    <Grid size={{ xs: 12, sm: 12, md: 4 }}>
                        <Box sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',

                            [theme.breakpoints.down('md')]: {
                                alignItems: 'center',
                                mb: 3,
                            }
                        }}>
                            {/* <Logo /> */}
                            <Box sx={{
                                // height: 150,

                                width: 220,
                                aspectRatio: '4 / 1',
                                position: 'relative',
                            }}>
                                <Image fill src={FooterLogo.src} alt="vahdatoptic" />
                            </Box>
                            <Box sx={{
                                display: "flex", mt: 3,

                                '& .social_item': {
                                    transition: '250ms',
                                    '&:hover': {
                                        transform: "scale(1.1) translateY(-2px)"

                                    }
                                },
                            }}>
                                <Box sx={{ mr: 2, ml: 0 }}
                                    component="a"
                                    aria-label="یوتیوب عینک وحدت"
                                    href="https://www.youtube.com/@vahdatoptic" target="_blank" className="social_item">
                                    <FontAwesomeIcon
                                        icon="fa-brands fa-youtube"
                                        size={32}
                                        // color="#F70015"
                                        color={colors.grey[500]}

                                    />
                                </Box>
                                <Box sx={{ mr: 2, ml: 0 }}
                                    component="a"
                                    aria-label="ارتباط با پشتیبانی عینک وحدت"
                                    href="https://t.me/Vahdatoptic1" target="_blank" className="social_item">
                                    <FontAwesomeIcon
                                        icon="fa-brands fa-telegram"
                                        size={32}
                                        // color="#009FD7"
                                        color={colors.grey[500]}
                                    />
                                </Box>
                                <Box sx={{ mr: 2, ml: 0 }}
                                    component="a"
                                    aria-label="ارتباط با پشتیبانی عینک وحدت"
                                    href="https://wa.me/+989104443316" target="_blank" className="social_item">
                                    <FontAwesomeIcon
                                        icon="fa-brands fa-whatsapp"
                                        size={32}
                                        // color="#00CC6C"
                                        color={colors.grey[500]}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        // height: 32,
                                        // width: 32,
                                        // borderRadius: '11px',
                                        // background: "linear-gradient( to right, #833ab4, #fd1d1d, #fcb045 )",
                                        // display: 'flex', alignItems: 'center', justifyContent: 'center'
                                    }}
                                    component="a"
                                    aria-label="اینستگرام عینک وحدت"
                                    href="https://www.instagram.com/vahdatoptic/" target="_blank"
                                    className="social_item">
                                    <FontAwesomeIcon
                                        icon="fa-brands fa-instagram"
                                        size={32}
                                        // color="#fff",
                                        color={colors.grey[500]}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 7, md: 4 }} p={{ xs: 3, md: 0 }} pt={'0 !important'}>
                        <Box>
                            <Box sx={{ mb: 2 }}>
                                <Typography
                                    sx={{
                                        fontSize: "16px !important",
                                        fontWeight: "800 !important",
                                        color: "#CC2941",
                                    }}
                                >
                                    ارتباط با ما
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    mb: 2,
                                }}
                            >
                                <Box sx={{ mr: 2, ml: 0 }}>
                                    <FontAwesomeIcon
                                        icon="fa-regular fa-location-dot"
                                        color="#8E8E8E"
                                    />
                                </Box>
                                <Box sx={{
                                    mb: 1, maxWidth: 320,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 'unset'
                                    }
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: "14px !important",
                                            fontWeight: "400 !important",
                                            color: "#585858",
                                            pr: 2
                                        }}
                                    >
                                        تهران، فلکه دوم صادقیه، بلوار آیت الله کاشانی، بعد از بلوار اباذر، نبش گلستان شمالی، پلاک ۷۰
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 2,
                                }}
                            >
                                <Box sx={{ mr: 2, ml: 0 }}>
                                    <FontAwesomeIcon icon="fa-light fa-mailbox"
                                        color="#8E8E8E"

                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: "14px !important",
                                        fontWeight: "400 !important",
                                        color: "#585858",
                                    }}
                                >
                                    کد پستی: ۱۴۸۱۸۷۴۵۶۱
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 2,
                                }}
                            >
                                <Box sx={{ mr: 2, ml: 0 }}>
                                    <FontAwesomeIcon
                                        icon="fa-light  fa-phone"
                                        color="#8E8E8E"
                                    />
                                </Box>
                                <Typography
                                    component={'a'}
                                    href={'tel:+982144042153'}
                                    sx={{
                                        fontSize: "14px !important",
                                        fontWeight: "400 !important",
                                        color: "#585858",
                                    }}
                                >
                                    تلفن: ۴۴۰۴۲۱۵۳-۰۲۱
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Box sx={{ mr: 2, ml: 0 }}>
                                    <FontAwesomeIcon
                                        icon="fa-light fa-envelope"
                                        color="#8E8E8E"
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: "14px !important",
                                        fontWeight: "400 !important",
                                        color: "#585858",
                                    }}
                                >
                                    ایمیل: vahdat.optic@gmail.com
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 3, md: 3 }} p={{ xs: 3, md: 0 }} pt={'0 !important'}>
                        <Box sx={{ display: "flex" }}>
                            <Box>
                                <Box sx={{ mb: 2 }}>
                                    <Typography
                                        sx={{
                                            fontSize: "16px !important",
                                            fontWeight: "800 !important",
                                            color: "#CC2941",
                                        }}
                                    >
                                        سایر لینک‌ها
                                    </Typography>
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <Link
                                        href={"/fa/pages/about-us"}
                                        prefetch={false}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "14px !important",
                                                fontWeight: "400 !important",
                                                color: "#585858",
                                            }}
                                        >
                                            درباره ما
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <Link
                                        href={"tel:02144042260"}
                                        prefetch={false}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "14px !important",
                                                fontWeight: "400 !important",
                                                color: "#585858",
                                            }}
                                        >
                                            تماس با ما
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <Link
                                        href={"/fa/pages/privacy"}
                                        prefetch={false}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "14px !important",
                                                fontWeight: "400 !important",
                                                color: "#585858",
                                            }}
                                        >
                                            حریم خصوصی
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <Link
                                        href={"/fa/pages/terms-and-conditions"}
                                        prefetch={false}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "14px !important",
                                                fontWeight: "400 !important",
                                                color: "#585858",
                                            }}
                                        >
                                            قوانین و مقررات
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <Link
                                        href={"/fa/faq"}
                                        prefetch={false}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "14px !important",
                                                fontWeight: "400 !important",
                                                color: "#585858",
                                            }}
                                        >
                                            سوالات متداول
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 2, md: 1 }}>
                        <Enemad />
                    </Grid>
                </Grid>
            </Box>

            <Grid container sx={{ mt: 4 }}>
                <Grid size={{ xs: 12 }}>
                    <Box
                        sx={{
                            bgcolor: "#424242",
                            height: 56,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "12px !important",
                                fontWeight: "400 !important",
                                color: "#fff",
                            }}
                        >
                            بخش فناوری اطلاعات شرکت بازرگانی اپتیک وحدت
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
