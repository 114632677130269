"use client"

import { Box, Skeleton } from "@mui/material";
import useOnLoadEvent from "@v2/hooks/useOnLoadEvent";
import { useState } from "react";

export default function Enemad() {

    const [loaded, setLoaded] = useState(false)

    useOnLoadEvent(() => {
        setLoaded(true)
    })

    return loaded ? (
        <Box
            sx={{
                height: 64
            }}
            dangerouslySetInnerHTML={{
                __html: `<a referrerpolicy='origin' aria-label='نماد اعتماد الکترونیک عینک وحدت' target='_blank' href='https://trustseal.enamad.ir/?id=540261&Code=8FsttFZSSMImYYaHOmNJWoHffILwjVMC'><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=540261&Code=8FsttFZSSMImYYaHOmNJWoHffILwjVMC' alt='' style='cursor:pointer' code='8FsttFZSSMImYYaHOmNJWoHffILwjVMC'></a>`,
            }}
        />
    ) : <Skeleton height={64} width={64} variant="rounded" />
}
